import { useState, useEffect} from "react"
import React from 'react'
import api from '../api'
import { chk_img, lang } from '../lang'

export default function Resource(props) {
    const [resources, setResources] = useState([]);
    const styles = props.styles;
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/menu?type=resource-menu');
              setResources(response.data.results);
              
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchInit();
    },[]);
    return (
        <div className={styles["resource"]}>
            <h2>{lang("บริการ","Our ")}<span>{lang("ของเรา","Services")}</span></h2>
            <ul className={styles["resource-card"]}>
            {resources.map((item, key) =>(
                <li key={key}>
                    <a href={item.link} target={item.target}>
                        <div className={styles["cover"]}><img src={chk_img(item.cover_img)} alt={lang(item.menu_th,item.menu_en)} width="50" height="50" /></div>
                        <p>{lang(item.menu_th,item.menu_en)}</p>
                    </a>
                </li>
            ))}
            </ul>
        </div>
    )
}
