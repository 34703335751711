import { useState, useEffect} from "react"
import api from '../api'
import { chk_img, lang, gotoURL, gotoLink } from '../lang'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import SwiperNavigation from "../Template/Swiper/SwiperNavigation";

SwiperCore.use([Navigation, Pagination, A11y]);
export default function Ebook(props) {
    const [resources, setResources] = useState([]);
    const [book_type, setBookType] = useState('e-book');
    const [book_name, setBookName] = useState('');
    const [author, setAuthor] = useState('');
    const [isbn, setISBN] = useState('');
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay
    const styles = props.styles;

    useEffect(()=>{
        const fetchBook = async () =>{
            try {
            //   const response = await api.get(`/book/type/${book_type}?show=12`);
              const response = await api.get(`/book?show=12`);
              setResources(response.data.results);
              if(response.data.results){
                // console.log(response.data.results);
                let book = response.data.results[0];
                setBookName(book.title);
                setAuthor(book.author);
                setISBN(book.isbn);
              }
              
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchBook();
    },[book_type]);

    const getBookInfo = (index) => {
        resources.forEach((item, key) => {
            if(key===index){
                setBookName(item.title);
                setAuthor(item.author);
                setISBN(item.isbn);
            }
        });
    }

    return (
        <div className={"ebook container "+styles[book_type]}>
            <div className={styles["book-shelf-menu"]}>
                <h2>New <span>Arrival</span></h2>
                <div className={styles["book-info"]}>
                    <h4>{book_name}</h4>
                    <div className={styles["book-detail"]}>
                        <p>{lang("ผู้แต่ง","Author")}: <span>{author}</span></p>
                        <p>{lang("หมายเลข ISBN","ISBN")}: <span>{isbn}</span></p>
                    </div>
                </div>
                {/* <ul>
                    <li onClick={e=>setBookType("book")} className={(book_type==="book")?styles["active"]:''}>{lang('หนังสือใหม่','New Books')}</li>
                    <li onClick={e=>setBookType("journal")} className={(book_type==="journal")?styles["active"]:''}>{lang('วารสาร','Journals')}</li>
                    <li onClick={e=>setBookType("e-book")} className={(book_type==="e-book")?styles["active"]:''}>{lang('หนังสืออิเล็กทรอนิกส์','E-book')}</li>
                    <li onClick={e=>setBookType("e-journal")} className={(book_type==="e-journal")?styles["active"]:''}>{lang('วารสารอิเล็กทรอนิกส์','E-Journals')}</li>
                </ul> */}
                <div className={styles["book-shelf-desktop"]}>
                {/* <SwiperNavigation section="ebook" /> */}
                </div>
            </div>
            <div className={styles["book-shelf-mobile"]}>
                <ul className={styles["ebook-card"]}>
                {resources.map((item, key) =>(
                    <li key={key}>
                        <a href={item.link} target={item.target}>
                            <div className={styles["cover"]+" group"}>
                                <img src={chk_img(item.img_url)} alt={lang(item.title,item.title)} width="154" height="202" />
                            </div>
                            <span>{item.title}</span>
                        </a>
                    </li>
                ))}
                </ul>
                <div className={styles["readmore"]} onClick={() => navigate('/books')}>{lang('ดูทั้งหมด','View All')}</div>
            </div>
            <div className={styles["book-shelf"]}>
                <div className={styles["ebook-card"]}>
                {/* group items  */}
                <Swiper
                    slidesPerView={4}
                    centeredSlides={false}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      640: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 640px
                      768: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 992
                      1024: {
                        slidesPerView: 3,
                      },
                      // when window width is >= 992
                      1028: {
                        slidesPerView: 4,
                      },
                      1521: {
                        slidesPerView: 4,
                      }
                    }}
                    loop={true}
                    onSlideChange={ (swiperCore) => {
                        const {
                          activeIndex,
                          snapIndex,
                          previousIndex,
                          realIndex,
                        } = swiperCore;
                        // console.log({ activeIndex, snapIndex, previousIndex, realIndex });
                        getBookInfo(realIndex);
                    }} 
                    navigation={{
                      nextEl: ".swiper-button-next-unique",
                      prevEl: ".swiper-button-prev-unique"
                    }}
                    // pagination={{
                    //     el: ".ebook-swiper-pagination",
                    //     type: "bullets",
                    //     clickable: true
                    // }}
                    // navigation={{
                    //   nextEl: ".art-ebook-swiper-next",
                    //   prevEl: ".art-ebook-swiper-prev"
                    // }}
                    // modules={[Pagination]}
                    effect={"fade"}
                    className="ebook-swiper"
                >
                    {resources.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={styles["card"]+" group"}>
                            {(item.book_type==="e-book"||item.book_type==="e-journal")?(
                                <div className={styles["cover"]+" group"}>
                                    <img src={chk_img(item.img_url)} onClick={e=>gotoLink(item.link)} alt={lang(item.title,item.title)} width="154" height="202" />
                                    <span>{item.title}</span>
                                </div>
                            ):(
                                <div className={styles["cover"]+" group"}>
                                    <img src={chk_img(item.img_url)} onClick={e=>gotoURL(process.env.PUBLIC_URL + "/book/"+item.id)} alt={lang(item.title,item.title)} width="154" height="202" />
                                    <span>{item.title}</span>
                                </div>
                            )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                    <div className="swiper-button-prev-unique"><FaChevronLeft /></div>
                    <div className="swiper-button-next-unique"><FaChevronRight /></div>
                </div>
                {/* <ul className={styles["ebook-card"]}>
                {resources.map((item, key) =>(
                    <li key={key}>
                        {(item.book_type==="e-book"||item.book_type==="e-journal")?(
                        <a href={item.link} target={item.target}>
                            <div className={styles["cover"]+" group"}>
                                <img src={chk_img(item.img_url)} alt={lang(item.title,item.title)} />
                            </div>
                            <span>{item.title}</span>
                        </a>
                        ):(
                            <a href={process.env.PUBLIC_URL + "/book/"+item.id} target={item.target}>
                                <div className={styles["cover"]+" group"}>
                                    <img src={chk_img(item.img_url)} alt={lang(item.title,item.title)} />
                                </div>
                                <span>{item.title}</span>
                            </a>
                        )}
                    </li>
                ))}
                </ul> */}
                <div className={styles["shelf"]}></div>
                <div className={styles["readmore"]} onClick={() => navigate('/resources')}>{lang('ดูทั้งหมด','View All')}</div>
            </div>
        </div>
    )
}
